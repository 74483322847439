import { EcSite } from '../apis/type/ec_site_pb'
import { Config, SiteConfig, Environment } from './index'

export { Environment } from './index'

const defaultSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.NO_TYPE,
  baseUrlRe: /^https:\/\/(dev|life-web-dev|(life|itoyokado)\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDBlRNVqyAX9qvTDsnrCLiIioiEqrQ2T2o',
    authDomain: 'stailer-dev.firebaseapp.com',
    databaseURL: 'https://stailer-dev.firebaseio.com',
    projectId: 'stailer-dev',
    storageBucket: 'stailer-dev.appspot.com',
    messagingSenderId: '109647832226',
    appId: '1:109647832226:web:417dc0ce88bd75014be3fb',
    measurementId: 'G-WQ9BBLCLEP',
  },
}

const demoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.DEMO,
  baseUrlRe: /^https:\/\/demo(-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDOq6GiTr86ZdwtTHNd9dTGd-BVWoTfe3Y',
    authDomain: 'stailer-demo-dev.firebaseapp.com',
    databaseURL: 'https://stailer-demo-dev.firebaseio.com',
    projectId: 'stailer-demo-dev',
    storageBucket: 'stailer-demo-dev.appspot.com',
    messagingSenderId: '71980302377',
    appId: '1:71980302377:web:fa7b18496d5422d1f4dc38',
    measurementId: 'G-PWFJ9GHDW2',
  },
}

const albisSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.ALBIS,
  baseUrlRe: /^https:\/\/albis(-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCZOxWGJLt1oYPIBk--6Cqosy2MV1B5gO8',
    authDomain: 'stailer-albis-dev.firebaseapp.com',
    databaseURL: 'https://stailer-albis-dev.firebaseio.com',
    projectId: 'stailer-albis-dev',
    storageBucket: 'stailer-albis-dev.appspot.com',
    messagingSenderId: '287199798574',
    appId: '1:287199798574:web:8b5bab1fde0af5bd5b46bf',
    measurementId: 'G-9EKMVQ7GM6',
  },
}

const frestaSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.FRESTA,
  baseUrlRe: /^https:\/\/fresta(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDDKw7Nk6CnS1PJZWWkggWglpjtTRV9QFM',
    authDomain: 'stailer-fresta-dev.firebaseapp.com',
    databaseURL: 'https://stailer-fresta-dev.firebaseio.com',
    projectId: 'stailer-fresta-dev',
    storageBucket: 'stailer-fresta-dev.appspot.com',
    messagingSenderId: '1006182858085',
    appId: '1:1006182858085:web:d4be0a293bda30e2dff58c',
    measurementId: 'G-VFPH1R44B3',
  },
}

const yakuodoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.YAKUODO,
  baseUrlRe: /^https:\/\/yakuodo(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCv8j7PPdZ3DLbH3CWyRwPUMzh2zwRaC4U',
    authDomain: 'stailer-yakuodo-dev.firebaseapp.com',
    databaseURL: 'https://stailer-yakuodo-dev.firebaseio.com',
    projectId: 'stailer-yakuodo-dev',
    storageBucket: 'stailer-yakuodo-dev.appspot.com',
    messagingSenderId: '1081405903175',
    appId: '1:1081405903175:web:8414b3a07972a4ba6bfc62',
    measurementId: 'G-0WQ2TYSLL9',
  },
}

const createsdSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.CREATESD,
  baseUrlRe: /^https:\/\/createsd(-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBXVaJwHrFo0ynHr_lX6CyT7bQfAIH9ZN0',
    authDomain: 'stailer-createsd-dev.firebaseapp.com',
    databaseURL: 'https://stailer-createsd-dev.firebaseio.com',
    projectId: 'stailer-createsd-dev',
    storageBucket: 'stailer-createsd-dev.appspot.com',
    messagingSenderId: '854008923461',
    appId: '1:854008923461:web:b3d1f35aa5aad514a1ea2c',
    measurementId: '',
  },
}

const heiwadoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.HEIWADO,
  baseUrlRe: /^https:\/\/heiwado(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBrtqW-XFL9KPkylhJ9W3PVhDfF7mEBFCE',
    authDomain: 'stailer-heiwado-dev.firebaseapp.com',
    databaseURL: 'https://stailer-heiwado-dev.firebaseio.com',
    projectId: 'stailer-heiwado-dev',
    storageBucket: 'stailer-heiwado-dev.appspot.com',
    messagingSenderId: '733563234706',
    appId: '1:733563234706:web:43e93da15d42348c2d794b',
    measurementId: '',
  },
}

const sugiSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.SUGI,
  baseUrlRe: /^https:\/\/sugi(-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyAAb9El5lbOUzfZQI5xC0TXezIe9srTi0A',
    authDomain: 'stailer-sugi-dev.firebaseapp.com',
    databaseURL: 'https://stailer-sugi-dev.firebaseio.com',
    projectId: 'stailer-sugi-dev',
    storageBucket: 'stailer-sugi-dev.appspot.com',
    messagingSenderId: '478240868952',
    appId: '1:478240868952:web:4ded6d1dc683f862b1582d',
    measurementId: '',
  },
}

const deliciaSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.DELICIA,
  baseUrlRe: /^https:\/\/delicia(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyCfkcwOrxlUwAjCXrWxnOeHJLaTmBRNTx4',
    authDomain: 'stailer-delicia-dev.firebaseapp.com',
    databaseURL: 'https://stailer-delicia-dev.firebaseio.com',
    projectId: 'stailer-delicia-dev',
    storageBucket: 'stailer-delicia-dev.appspot.com',
    messagingSenderId: '838641548940',
    appId: '1:838641548940:web:82695456b46c740b2805a9',
    measurementId: '',
  },
}

const ladydrugSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.LADYDRUG,
  baseUrlRe: /^https:\/\/ladydrug(-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyDBbCe_oK_FfpizuEw0Q4jSbkbc4Zq7E84',
    authDomain: 'stailer-ladydrug-dev.firebaseapp.com',
    databaseURL: 'https://stailer-ladydrug-dev.firebaseio.com',
    projectId: 'stailer-ladydrug-dev',
    storageBucket: 'stailer-ladydrug-dev.appspot.com',
    messagingSenderId: '246802416926',
    appId: '1:246802416926:web:6ce866e18532c508a8750f',
    measurementId: 'G-Q95616230P',
  },
}

const oginoSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.OGINO,
  baseUrlRe: /^https:\/\/ogino(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBmzCK0HWjLJYEKVwCK6XQ-VjSvCmuc3nM',
    authDomain: 'stailer-ogino-dev.firebaseapp.com',
    databaseURL: 'https://stailer-ogino-dev.firebaseio.com',
    projectId: 'stailer-ogino-dev',
    storageBucket: 'stailer-ogino-dev.appspot.com',
    messagingSenderId: '634706315197',
    appId: '1:634706315197:web:702a318796eb03dddbb4d4',
    measurementId: 'G-ZCJBHLE9PL',
  },
}

const torisenSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.TORISEN,
  baseUrlRe: /^https:\/\/torisen(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyAwia5aov6PyhZeBPzJtKzQ6C0cyJcWGa8',
    authDomain: 'stailer-torisen-dev.firebaseapp.com',
    databaseURL: 'https://stailer-torisen-dev.firebaseio.com',
    projectId: 'stailer-torisen-dev',
    storageBucket: 'stailer-torisen-dev.appspot.com',
    messagingSenderId: '194154534503',
    appId: '1:194154534503:web:dff3691a1b996f4897e6c2',
    measurementId: 'G-8WWQ814Q8D',
  },
}

const seisenichibatopSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.SEISENICHIBATOP,
  baseUrlRe: /^https:\/\/seisenichibatop(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyA7LIAr37Wq-a0tGIVOZo-F6M4Kp4ffd88',
    authDomain: 'stailer-seisenichibatop-dev.firebaseapp.com',
    databaseURL: 'https://stailer-seisenichibatop-dev.firebaseio.com',
    projectId: 'stailer-seisenichibatop-dev',
    storageBucket: 'stailer-seisenichibatop-dev.appspot.com',
    messagingSenderId: '197314524579',
    appId: '1:197314524579:web:6f0828dc791607c07bd616',
    measurementId: 'G-YJTXRZ77NP',
  },
}

const arukSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.ARUK,
  baseUrlRe: /^https:\/\/aruk(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBYUCvKSQF4yZZ6FdKRlq4BkxHGQfKiN2g',
    authDomain: 'stailer-aruk-dev.firebaseapp.com',
    databaseURL: 'https://stailer-aruk-dev.firebaseio.com',
    projectId: 'stailer-aruk-dev',
    storageBucket: 'stailer-aruk-dev.appspot.com',
    messagingSenderId: '202241741121',
    appId: '1:202241741121:web:408664b2a87508228691c0',
    measurementId: 'G-77M623B6SR',
  },
}

const fujiSiteConfig: SiteConfig = {
  siteType: EcSite.SiteType.FUJI,
  baseUrlRe: /^https:\/\/fuji(-dev|-web-dev|\.admin\.develop)\.stailer\.jp\//,
  firebaseConfig: {
    apiKey: 'AIzaSyBKk-dSEQmJgJZzliIrbDZF5OkY6mVQifU',
    authDomain: 'stailer-fuji-dev.firebaseapp.com',
    databaseURL: 'https://stailer-fuji-dev.firebaseio.com',
    projectId: 'stailer-fuji-dev',
    storageBucket: 'stailer-fuji-dev.appspot.com',
    messagingSenderId: '429806599050',
    appId: '1:429806599050:web:536b958de878b301c0a673',
    measurementId: 'G-HZ7ZHN4Q47',
  },
}

const siteConfigMapping: { [domain: string]: SiteConfig } = {
  // パートナー用のドメイン
  'dev.stailer.jp': defaultSiteConfig,
  'demo-dev.stailer.jp': demoSiteConfig,
  'albis-dev.stailer.jp': albisSiteConfig,
  'fresta-dev.stailer.jp': frestaSiteConfig,
  'yakuodo-dev.stailer.jp': yakuodoSiteConfig,
  'createsd-dev.stailer.jp': createsdSiteConfig,
  'heiwado-dev.stailer.jp': heiwadoSiteConfig,
  'sugi-dev.stailer.jp': sugiSiteConfig,
  'delicia-dev.stailer.jp': deliciaSiteConfig,
  'ladydrug-dev.stailer.jp': ladydrugSiteConfig,
  'ogino-dev.stailer.jp': oginoSiteConfig,
  'torisen-dev.stailer.jp': torisenSiteConfig,
  'seisenichibatop-dev.stailer.jp': seisenichibatopSiteConfig,
  'aruk-dev.stailer.jp': arukSiteConfig,
  'fuji-dev.stailer.jp': fujiSiteConfig,
  // 10X 社内用のドメイン
  'demo.admin.develop.stailer.jp': demoSiteConfig,
  'albis.admin.develop.stailer.jp': albisSiteConfig,
  'fresta.admin.develop.stailer.jp': frestaSiteConfig,
  'yakuodo.admin.develop.stailer.jp': yakuodoSiteConfig,
  'createsd.admin.develop.stailer.jp': createsdSiteConfig,
  'heiwado.admin.develop.stailer.jp': heiwadoSiteConfig,
  'sugi.admin.develop.stailer.jp': sugiSiteConfig,
  'delicia.admin.develop.stailer.jp': deliciaSiteConfig,
  'ladydrug.admin.develop.stailer.jp': ladydrugSiteConfig,
  'itoyokado.admin.develop.stailer.jp': defaultSiteConfig,
  'life.admin.develop.stailer.jp': defaultSiteConfig,
  'ogino.admin.develop.stailer.jp': oginoSiteConfig,
  'torisen.admin.develop.stailer.jp': torisenSiteConfig,
  'seisenichibatop.admin.develop.stailer.jp': seisenichibatopSiteConfig,
  'aruk.admin.develop.stailer.jp': arukSiteConfig,
  'fuji.admin.develop.stailer.jp': fujiSiteConfig,
}

const config: Config = {
  siteConfigMapping,
  apiBaseUrl: '/rpc',
  environment: Environment.development,
}
export default config
